(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = function _default() {
  var jobs = _toConsumableArray(document.querySelectorAll('.j-job'));

  var jobFilter = _toConsumableArray(document.querySelectorAll('.j-job-filter'));

  var defaultFilter = document.querySelector('.j-job-filter[value="-1"]');
  var selectedFilter = -1;
  var selectedFilters = [];

  var resetFilters = function resetFilters() {
    selectedFilter = -1;
    selectedFilters = [];
    jobFilter.forEach(function (filter) {
      filter.checked = false;
    });

    if (defaultFilter) {
      defaultFilter.checked = true;
    }
  };

  var resetJobs = function resetJobs() {
    jobs.forEach(function (job) {
      return job.classList.remove('is-hidden');
    });
  };

  var addFilter = function addFilter(filter) {
    selectedFilters = [].concat(_toConsumableArray(selectedFilters), [filter]);
  };

  var removeFilter = function removeFilter(filter) {
    selectedFilters = selectedFilters.filter(function (item) {
      return item !== filter;
    });
  };

  var filterJobs = function filterJobs(selectedFilter, selectedFilters) {
    if (selectedFilter === -1) {
      resetJobs();
      return;
    }

    jobs.forEach(function (job) {
      var jobCategories = JSON.parse(job.dataset.categories);
      var jobCategoryIds = jobCategories.map(function (jobCategory) {
        return jobCategory;
      });
      var matchedCategoryCount = jobCategoryIds.filter(function (jobCategoryId) {
        return selectedFilters.indexOf(jobCategoryId) !== -1;
      });
      job.classList.remove('is-hidden');

      if (!matchedCategoryCount.length) {
        job.classList.add('is-hidden');
      }
    });
  };

  jobFilter.forEach(function (jobFilter) {
    jobFilter.addEventListener('change', function () {
      selectedFilter = parseInt(jobFilter.value);

      if (selectedFilter > 0) {
        defaultFilter.checked = false;
      }

      if (selectedFilter === -1) {
        resetFilters();
      }

      if (jobFilter.checked && selectedFilter > 0) {
        addFilter(selectedFilter);
      }

      if (!jobFilter.checked && selectedFilter > 0) {
        var checkedFilters = _toConsumableArray(document.querySelectorAll('.j-job-filter:checked'));

        removeFilter(selectedFilter);

        if (!checkedFilters.length) {
          resetFilters();
        }
      }

      filterJobs(selectedFilter, selectedFilters);
    });
  });
};

exports.default = _default;

},{}],2:[function(require,module,exports){
"use strict";

var _jobFilter = _interopRequireDefault(require("./Component/job-filter"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _jobFilter.default)();

},{"./Component/job-filter":1}]},{},[2]);
